import React, { Component } from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import "bootstrap/dist/css/bootstrap.min.css";
import PulseLoader from "react-spinners/PulseLoader";
import CountUp from "react-countup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileMedicalAlt, faMailBulk, faUserAlt, faUserMd } from "@fortawesome/free-solid-svg-icons";
const year = new Date().getFullYear();
const month = ("0" + (new Date().getMonth() + 1)).slice(-2);
const total = "" + year + month;
const diff = total - 202409;

class Landing extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    if (this.props.auth.user === "david.bigoness@animalmedexpress.com") {
      window.location.href =
        "https://animalmedrx.com?goto=dashboard&reference=" +
        localStorage.getItem("jwtToken");
    }
  }

  openLink = () => {
    window
      .open("https://calendly.com/rxconnexion/30min?month=2023-11", "_blank")
      .focus();
  };

  render() {
    return (
      <div className="d-flex landing" id="wrapper">
        <div id="page-content-wrapper">
          <div className="container-fluid">
            {this.state.loading && (
              <div className="loading-wrapper">
                <PulseLoader color="#007bff" loading={true} size={20} />
              </div>
            )}
            <br></br>
            <button
              type="button"
              class="btn btn-success float-right mobile-float-left"
              onClick={this.openLink}
            >
              <b>Schedule Consultation</b>
            </button>
            <h2
              className="mt-2 text-primary"
              style={{ fontWeight: "bold" }}
            >
              Welcome to RxConnexion
            </h2>
            <h4>
              We provide a complete suite of products and solutions to help community pharmacies grow and thrive. At RxConnexion, we offer telemedicine platforms, social media management, custom websites, SEO, reputation management, fax and text marketing, patient outreach tools, and Chronic Care Management services. Our digital solutions are designed to streamline operations, boost patient engagement, enhance your online presence, and increase revenue.
              <br></br>
              <br></br>
              Please select the solution on left to start
            </h4>
            <br></br>
            <br></br>
            <Row>
              <div className="col-sm-12 p-sm-3">
                <div className="card ">
                  <div className="card-body dashboard">
                    <Row>
                      <div className="col-sm-3 p-sm-2">
                        <div
                          className="card text-white "
                          style={{ backgroundColor: "rgb(241, 79, 33)" }}
                        >
                          <div className="card-body">
                            <Row>
                              <div className="col-sm-4 ">
                                <div className="counter-icon"><FontAwesomeIcon icon={faUserMd} /></div>
                              </div>
                              <div className="col-sm-8 p-sm-2"> <h4 className="card-title">Prescribing Data</h4>
                                <h4>
                                  <CountUp end={26136781} duration={5} />
                                </h4></div>
                            </Row>

                          </div>
                        </div>
                      </div>
                      <div className="col-sm-3 p-sm-2">
                        <div
                          className="card text-white "
                          style={{ backgroundColor: "rgb(126, 185, 0)" }}
                        >
                          <div className="card-body">
                            <Row>
                              <div className="col-sm-4">
                                <div className="counter-icon" style={{ backgroundColor: "rgb(96, 185, 0)" }}><FontAwesomeIcon icon={faUserMd} /></div>
                              </div>
                              <div className="col-sm-8 p-sm-2"> <h4 className="card-title">Provider Database</h4>
                                <h4>
                                  <CountUp end={8102528} duration={5} />
                                </h4></div>
                            </Row>
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-3 p-sm-2">
                        <div
                          className="card text-white "
                          style={{ backgroundColor: "rgb(0, 163, 238)" }}
                        >
                          <div className="card-body">
                            <Row>
                              <div className="col-sm-4 ">
                                <div className="counter-icon" style={{ backgroundColor: "rgb(2 157 228)" }}><FontAwesomeIcon icon={faUserAlt} /></div>
                              </div>
                              <div className="col-sm-8 p-sm-2"> <h4 className="card-title">Pharmacies</h4>
                                <h4>
                                  <CountUp end={707 + (diff * 20)} duration={5} />
                                </h4></div>
                            </Row>
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-3 p-sm-2">
                        <div
                          className="card text-white "
                          style={{ backgroundColor: "rgb(254, 184, 0)" }}
                        >
                          <div className="card-body">
                            <Row>
                              <div className="col-sm-4">
                                <div className="counter-icon" style={{ backgroundColor: "rgb(224, 184, 0)" }}><FontAwesomeIcon icon={faMailBulk} /></div>
                              </div>
                              <div className="col-sm-8 p-sm-2"> <h4 className="card-title">Provider email</h4>
                                <h4>
                                  <CountUp end={264000 + (diff * 50)} duration={5} />
                                </h4></div>
                            </Row>
                          </div>
                        </div>
                      </div>
                    </Row>
                  </div>
                </div>
              </div>
            </Row>
            <br></br>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  "https://d3l9g5ay50k79j.cloudfront.net/Lady+RPh+at+counter.jpg"
                }
                alt="Landing"
                style={{ width: "100%" }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(Landing);
