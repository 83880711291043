import axios from "axios";
import React, { useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { useHistory } from "react-router-dom";
import PulseLoader from "react-spinners/ClockLoader";
const PrescriberRx = (props) => {
  const [tempalteName, setTemplateName] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const onFaxSend = (e) => {
    e.preventDefault();
    let filterData = {
      points: props.totalNpis,
      email: props.email,
      filters: props.filters,
      tempalteId: tempalteName,
      pageCount: pageCount,
      excludes: props.excludes || [],
    };
    setLoading(true);
    axios
      .post(props.apiUrl, filterData)
      .then((res) => {
        setLoading(false);
        if (!res.data.success) {
          confirmAlert({
            title: "Error",
            message:
              "You dont have enough credit left. Please add in order to send fax",
            buttons: [
              {
                label: "OK",
                onClick: () => history.push("/fax-credits"),
              },
              {
                label: "Cancel",
                onClick: () => {},
              },
            ],
          });
        } else {
          confirmAlert({
            title: "Success",
            message: res.data.message,
            buttons: [
              {
                label: "OK",
                onClick: () => (window.location.href = props.redirectUrl),
              },
            ],
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  return (
    <div>
      <div
        className="modal fade"
        id="send-faxtemplate-modal"
        data-reset="false"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Send Fax Template</h4>

              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body">
              <form noValidate onSubmit={onFaxSend} id="send-faxtemplate">
                <div className="col-md-4">
                  <label htmlFor="name"> Select Fax Template</label>
                </div>
                <div className="col-md-9">
                  <select
                    className="form-control"
                    onChange={(e) => {
                      setTemplateName(e.target.value);
                      setPageCount(
                        props.record.find((each) => each._id === e.target.value)
                          .page_count
                      );
                    }}
                    id="template_name"
                  >
                    <option value="">Please Select</option>
                    {props.record.map((each) => {
                      return (
                        <option key={each._id} value={each._id}>
                          {each.template_name}
                        </option>
                      );
                    })}
                  </select>
                  {tempalteName !== "" && pageCount > 0
                    ? `1 credit per page.`
                    : ""}
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <small className="font-red">
                Failed faxes will be credited back within 24 hours
              </small>
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                form="send-faxtemplate"
                type="submit"
                className="btn btn-primary"
              >
                Send Fax
              </button>
            </div>
            {loading && (
              <div className="loading-wrapper">
                <PulseLoader color="#007bff" loading={true} size={80} />
                Please Wait...
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default PrescriberRx;
