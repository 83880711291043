import React, { Component, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactDatatable from "../../utils/ReactDatatable";
import { logoutUser, setFilter } from "../../actions/authActions";
import { connect } from "react-redux";
import axios from "axios";
import { faPlus, faBackward } from "@fortawesome/free-solid-svg-icons";
import DoctorAddModal from "../partials/DoctorAddModal";
import DoctorUpdateModal from "../partials/DoctorUpdateModal";
import { toast, ToastContainer } from "react-toastify";
import { Collapse } from "react-collapse";
import PulseLoader from "react-spinners/PulseLoader";
import "react-datepicker/dist/react-datepicker.css";
import Multiselect from "multiselect-react-dropdown";
import { Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import moment from "moment";
import { get } from "lodash";
import $ from "jquery";
import FaxTemplatePlan from "../partials/FaxTemplatePlan";
import SendFax from "../partials/SendFax/Npi";
import usState from "../../utils/state.json";

class Doctors extends Component {
  constructor(props) {
    super(props);
    // this.notify = this.notify.bind(this);

    this.columns = [
      {
        key: "npi",
        text: "NPI",
        className: "npi",
        align: "left",
        sortable: true,
        link: "npis",
      },
      {
        key: "first_name",
        text: "First Name",
        className: "first_name",
        align: "left",
        sortable: true,
      },
      {
        key: "middle_name",
        text: "Middle Name",
        className: "middle_name",
        align: "left",
        sortable: true,
      },
      {
        key: "last_name",
        text: "Last Name",
        className: "last_name",
        align: "left",
        sortable: true,
      },
      {
        key: "account_name",
        text: "Organization",
        className: "account_name",
        align: "left",
        sortable: true,
      },
      {
        key: "address_city",
        text: "City",
        className: "address_city",
        align: "left",
        sortable: true,
      },
      {
        key: "address_state",
        text: "State",
        className: "address_state",
        align: "left",
        sortable: true,
      },
      {
        key: "address_postal",
        text: "Postal",
        className: "address_postal",
        align: "left",
        sortable: true,
      },
      {
        key: "address_telephone",
        text: "Telephone",
        className: "address_telephone",
        align: "left",
        sortable: true,
      },
    ];

    if (this.props.auth.user.superadmin) {
      this.columns.push({
        key: "action",
        text: "Action",
        className: "action",
        width: 100,
        align: "left",
        sortable: false,
        cell: (record) => {
          return (
            <Fragment>
              <button
                data-toggle="modal"
                data-target="#update-doctor-modal"
                className="btn btn-primary btn-sm"
                onClick={() => this.editRecord(record)}
                style={{ marginRight: "5px" }}
              >
                <i className="fa fa-edit"></i>
              </button>
              <button
                className="btn btn-danger btn-sm"
                onClick={() => this.deleteRecord(record)}
              >
                <i className="fa fa-trash"></i>
              </button>
            </Fragment>
          );
        },
      });
    }

    this.config = {
      page_size: 20,
      length_menu: [20, 50, 100],
      filename: "Npis",
      no_data_text: "No Records found!",
      button: {
        excel: false,
        print: false,
        csv: true,
        sendFax: true,
        // address_postal: true
      },
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      show_length_menu: true,
      show_filter: false,
      show_pagination: true,
      show_info: true,
    };

    this.state = {
      records: [],
      fax_records: [],
      taxonomies: [],
      totalCount: 0,
      filterData: {
        page_number: 1,
        page_size: 20,
        sort_order: { column: "_id", order: "asc" },
      },
      currentRecord: {},
      npi: undefined,
      first_name: undefined,
      last_name: undefined,
      address_city: undefined,
      address_state: undefined,
      address_postal: undefined,
      address_telephone: undefined,
      advanceSearch: undefined,
      taxonomy: [],
      loading: false,
      isFilterOpen: true,
    };

    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    if (get(this.props, "filter.npi.npiPage") === true) {
      this.setState({
        ...this.props.filter.npi,
      });
    } else {
      this.getData();
      this.getTaxonomy();
      this.getFaxCredits();
      this.getFaxTemplate();
    }
  }

  componentWillUnmount() {
    if (this.props.history.location.pathname.includes("npis/")) {
      this.state.npiPage = true;
      this.props.setFilter({ npi: this.state });
    } else {
      this.props.setFilter({});
    }
  }

  getTaxonomy() {
    axios
      .post("/api/taxonomy", {})
      .then((res) => {
        let taxonomies = [];
        for (let i = 0; i < res.data.taxonomy.length; i++) {
          let each = res.data.taxonomy[i];
          const Tcount = taxonomies.find(
            (current) => current.label === each.classification
          );
          if (Tcount) {
            Tcount.value = Tcount.value + "," + each.code;
          } else {
            taxonomies.push({ value: each.code, label: each.classification });
          }
        }
        taxonomies.sort((a, b) => a.label.localeCompare(b.label));

        this.setState({
          taxonomies: taxonomies,
        });
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  }
  onZipAdd = (e) => {
    e.preventDefault();
    const newDrug = {
      zipgroup: this.state.address_postal,
      pharmacy_account: this.props.auth.user.final_user,
    };
    axios.post("/api/zip-add", newDrug).then((response) => {
      toast(response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    });
  };
  getData() {
    this.setState({ loading: true });
    let filterData = this.state.filterData;
    filterData.search = {
      ...(this.state.npi !== "" && { npi: this.state.npi }),
      ...(this.state.first_name !== "" && {
        first_name: this.state.first_name,
      }),
      ...(this.state.last_name !== "" && { last_name: this.state.last_name }),
      ...(this.state.address_city !== "" && {
        address_city: this.state.address_city,
      }),
      ...(this.state.address_state !== "" && {
        address_state: this.state.address_state,
      }),
      ...(this.state.address_postal !== "" &&
        this.state.address_postal !== undefined && {
          address_postal: this.state.address_postal
            .split(" ")
            .join("")
            .split(","),
        }),
      ...(this.state.address_telephone !== "" && {
        address_telephone: this.state.address_telephone,
      }),
      ...(this.state.advanceSearch !== "" && {
        advanceSearch: this.state.advanceSearch,
      }),
      ...(this.state.taxonomy.length > 0 && {
        taxonomy: this.state.taxonomy.map((a) => a.value),
      }),
    };
    axios
      .post("/api/doctors", filterData)
      .then((res) => {
        this.setState({
          records: res.data.records,
          totalCount: res.data.count,
          loading: false,
        });
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  }

  editRecord(record) {
    this.setState({ currentRecord: record });
  }

  deleteRecord(record) {
    axios
      .post("/api/doctor-delete", { _id: record._id })
      .then((res) => {
        if (res.status === 200) {
          toast(res.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
    this.getData();
  }

  advanceSearch = (advanceSearch) => {
    this.setState(
      {
        npi: undefined,
        first_name: undefined,
        last_name: undefined,
        address_city: undefined,
        address_state: undefined,
        address_postal: undefined,
        address_telephone: undefined,
        advanceSearch: advanceSearch,
        taxonomy: [],
        filterData: {
          page_number: 1,
          page_size: 20,
          sort_order: { column: "_id", order: "asc" },
        },
      },
      () => this.getData()
    );
  };

  filter = (filterData) => {
    this.setState({ filterData }, () => this.getData());
  };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  setFromDate = (date) => {
    this.setState({ from_date: date });
  };
  setToDate = (date) => {
    this.setState({ to_date: date });
  };

  onChangeSelect = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  onSearch = (e) => {
    e.preventDefault();
    this.getData();
  };

  resetSearch = () => {
    this.setState(
      {
        npi: undefined,
        first_name: undefined,
        last_name: undefined,
        address_city: undefined,
        address_state: undefined,
        address_postal: undefined,
        address_telephone: undefined,
        taxonomy: [],
        filterData: {
          page_number: 1,
          page_size: 20,
          sort_order: { column: "_id", order: "asc" },
        },
      },
      () => this.getData()
    );
  };

  toggleChange = () => {
    this.setState({
      isFilterOpen: !this.state.isFilterOpen,
    });
  };

  onChangeReactSelect = (selectedOption) => {
    this.setState({ taxonomy: selectedOption.value });
  };

  exportToCSV = (address_postal) => {
    let filterData = {
      page_number: 1,
      page_size: 20000,
      sort_order: { column: "_id", order: "asc" },
    };
    this.setState({ loading: true });
    filterData.search = {
      ...(this.state.npi !== "" && { npi: this.state.npi }),
      ...(this.state.first_name !== "" && {
        first_name: this.state.first_name,
      }),
      ...(this.state.last_name !== "" && { last_name: this.state.last_name }),
      ...(this.state.address_city !== "" && {
        address_city: this.state.address_city,
      }),
      ...(this.state.address_state !== "" && {
        address_state: this.state.address_state,
      }),
      ...(this.state.address_postal !== "" &&
        this.state.address_postal !== undefined && {
          address_postal: this.state.address_postal.split(","),
        }),
      ...(this.state.address_telephone !== "" && {
        address_telephone: this.state.address_telephone,
      }),
      ...(this.state.advanceSearch !== "" && {
        advanceSearch: this.state.advanceSearch,
      }),
      ...(this.state.taxonomy.length > 0 && {
        taxonomy: this.state.taxonomy.map((a) => a.value),
      }),
    };
    if (
      this.state.address_postal === undefined &&
      this.props.auth.user.superadmin !== true
    ) {
      this.setState({ loading: false });

      toast.error(" Please Fill ZIP Code", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.success("Dowloading...Please Wait!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      axios

        .post("/api/doctors", filterData)
        .then((res) => {
          // else{

          var headers = {
            npi: "Npi",
            account_name: "Account Name",
            entity_type_code: "Entity Type Code",
            last_name: "Last Name",
            first_name: "First Name",
            middle_name: "Middle Name",
            name_prefix: "Name Prefix",
            name_suffix: "Name Suffix",
            address_first_line: "Address First Line",
            address_second_line: "Address Second Line",
            address_city: "Address City",
            address_state: "Address State",
            address_postal: "Address Postal",
            address_telephone: "Address Telephone",
            address_fax: "Address Fax",
            practice_postal: "Practice Postal",
            practice_telephone: "Practice Telephone",
            practice_fax: "Practice Fax",
            enumeration_date: "Enumeration Date",
            updated_date: "Updated Date",
            taxonomy_1: "Taxonomy_1",
            license_number_1: "License Number_1",
            license_number_state_1: "License Number State_1",
            license_number_primary_1: "License Number Primary_1",
            taxonomy_2: "Taxonomy_2",
            license_number_2: "License Number_2",
            license_number_state_2: "License Number State_2",
            license_number_primary_2: "License Number Primary_2",
            taxonomy_3: "Taxonomy_3",
            license_number_3: "License Number_3",
            license_number_state_3: "License Number State_3",
            license_number_primary_3: "License Number Primary_3",
          };
          // // add columns in sheet array
          // for (let column of this.props.columns) {
          //     headers[column.key] = '"' + column.text + '"';
          // }
          // if (address_postal === undefined){
          // <br onClick={this.notify}></br>

          // }
          // else{
          var records = [];
          // add data rows in sheet array
          //          if(this.state.address_postal === ''){
          //             console.log("shivank")
          //         }
          // else{
          this.setState({ loading: false });
          for (let i in res.data.records) {
            let record = res.data.records[i];
            delete record._id;
            delete record.id;
            delete record.__v;
            record.account_name = '"' + record.account_name + '"';
            records.push(record);
          }
          if (headers) {
            records.unshift(headers);
          }
          // Convert Object to JSON

          let jsonObject = JSON.stringify(records);
          let csv = this.convertToCSV(jsonObject, headers);
          let exportedFilenmae = this.config.filename + ".csv" || "export.csv";
          let blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
          if (navigator.msSaveBlob) {
            // IE 10+
            navigator.msSaveBlob(blob, exportedFilenmae);
            // <button  onClick={() =>this.notify}></button>
          } else {
            let link = document.createElement("a");
            if (link.download !== undefined) {
              // feature detection
              // Browsers that support HTML5 download attribute
              let url = URL.createObjectURL(blob);
              link.setAttribute("href", url);
              link.setAttribute("download", exportedFilenmae);
              link.style.visibility = "hidden";
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }
          }
        })
        .catch(function (error) {
          if (error.response) {
            if (error.response.status === 403) {
              window.location.href = "./login";
            }
          }
        });
    }
  };
  convertToCSV(objArray, headers) {
    const keys = Object.keys(headers);
    let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    let str = "";
    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in keys) {
        line +=
          array[i][keys[index]] === undefined
            ? ","
            : `"${array[i][keys[index]]}",`;
        line.trim(",");
      }
      str += line + "\r\n";
    }
    return str;
  }
  exportToPDF = (from, to) => {
    let filterData = {
      page_number: 1,
      page_size: 20000,
      sort_order: { column: "_id", order: "asc" },
    };
    this.setState({ loading: true });
    filterData.search = {
      ...(this.state.npi !== "" && { npi: this.state.npi }),
      ...(this.state.first_name !== "" && {
        first_name: this.state.first_name,
      }),
      ...(this.state.last_name !== "" && { last_name: this.state.last_name }),
      ...(this.state.address_city !== "" && {
        address_city: this.state.address_city,
      }),
      ...(this.state.address_state !== "" && {
        address_state: this.state.address_state,
      }),
      ...(this.state.address_postal !== "" && {
        address_postal: this.state.address_postal,
      }),
      ...(this.state.address_telephone !== "" && {
        address_telephone: this.state.address_telephone,
      }),
      ...(this.state.advanceSearch !== "" && {
        advanceSearch: this.state.advanceSearch,
      }),
      ...(this.state.taxonomy.length > 0 && {
        taxonomy: this.state.taxonomy.map((a) => a.value),
      }),
    };
    axios.post("/api/export-doctors", filterData).then((res) => {
      this.setState({ loading: false });
      let sTable = "";
      sTable += "<table>";
      sTable += "<thead>";
      sTable += "<tr>";
      for (let column of this.columns) {
        sTable += "<th>" + column.text + "</th>";
      }
      sTable += "</tr>";
      sTable += "</thead>";
      sTable += "<tbody>";
      for (let i in res.data.records) {
        let record = res.data.records[i];
        sTable += "<tr>";
        for (let column of this.columns) {
          if (column.cell && typeof column.cell === "function") {
            sTable += "<td></td>";
          } else if (record[column.key]) {
            sTable += "<td>" + record[column.key] + "</td>";
          } else {
            sTable += "<td></td>";
          }
        }
        sTable += "</tr>";
      }
      sTable += "</tbody>";
      sTable += "</table>";

      var style = "<style>";
      style = style + "table {width: 100%;font: 17px Calibri;}";
      style =
        style +
        "table, th, td {border: solid 1px #DDD; border-collapse: collapse;";
      style = style + "padding: 2px 3px;textAlign:left;}";
      style = style + "</style>";

      var win = window.open("", "_blank");
      win.document.write("<html><head>");
      win.document.write("<title>" + this.config.filename + "</title>");
      win.document.write(style);
      win.document.write("</head>");
      win.document.write("<body>");
      win.document.write("<h1>" + this.config.filename + "</h1>");
      win.document.write(sTable);
      win.document.write("</body></html>");
      win.print();
      win.close();
    });
  };

  onSelectMultiple = (selectedList, selectedItem) => {
    this.setState({ taxonomy: selectedList });
  };

  SendFax = () => {
    // confirmAlert({
    //   title: "Error",
    //   message:
    //     "The system is under maintenance for 5-7 days. Sorry for any inconveninece.",
    //   buttons: [
    //     {
    //       label: "OK",
    //       onClick: () => {},
    //     },
    //   ],
    // });

    // $("#send-faxtemplate-modal").modal("show");
    if (this.state.totalCount === 0) {
      confirmAlert({
        title: "Error",
        message: "No Prescriber found. Please search with valid filters",
        buttons: [
          {
            label: "OK",
            onClick: () => {},
          },
        ],
      });
    } else {
      if (
        this.props.auth.user.expiration_tabula === "" ||
        this.props.auth.user.expiration_tabula === undefined ||
        this.props.auth.user.expiration_tabula < moment().format()
      ) {
        confirmAlert({
          title: "Attention",
          message:
            "This feature is available for TabulaRx Subscribed User. Would you like to subscribe now?",
          buttons: [
            {
              label: "Yes",
              onClick: () =>
                (window.location.href = "/choose-plan?solution=TabulaRx"),
            },
            {
              label: "No",
              onClick: () => {},
            },
          ],
        });
      } else {
        if (
          (this.state.address_postal === undefined ||
            !this.state.taxonomy.length) &&
          (this.state.address_city === undefined ||
            this.state.address_state === undefined ||
            !this.state.taxonomy.length)
        ) {
          if (!this.state.taxonomy.length) {
            toast.error(" Please Select atleast one taxonomy", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else if (
            this.state.address_postal === undefined &&
            this.state.address_city === undefined &&
            this.state.address_state === undefined
          ) {
            toast.error(" Please Fill City and State or Zip ", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else if (
            (this.state.address_city !== undefined ||
              this.state.address_state === undefined ||
              this.state.address_postal === undefined) &&
            (this.state.address_city === undefined ||
              this.state.address_state !== undefined ||
              this.state.address_postal === undefined)
          ) {
            toast.error(" Please Fill City And State", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        } else if (
          parseInt(this.state.fax_points || 0) >= this.state.totalCount
        ) {
          $("#send-faxtemplate-modal").modal("show");
        } else {
          confirmAlert({
            title: "Attention",
            message:
              "You need" +
              "    " +
              (parseInt(this.state.totalCount) -
                parseInt(this.state.fax_points || 0)) +
              "  " +
              "More Credits to Send fax",
            buttons: [
              {
                label: "Ok",
                onClick: () =>
                  (window.location.href = "/fax-credits?show-popup=true"),
              },
              {
                label: "Cancel",
                onClick: () => {},
              },
            ],
          });
        }
      }
    }
  };
  getFaxCredits() {
    let filterData = {
      page_number: 1,
      page_size: 20,
      sort_order: { column: "_id", order: "desc" },
    };
    filterData.search = {
      email: this.props.auth.user.final_user,
    };
    axios
      .post("/api/user-data", filterData)
      .then((res) => {
        if (res.data.records.length) {
          console.log(res.data.records[0].fax_points);
          this.setState({
            fax_points: res.data.records[0].fax_points || 0,
            address_postal: res.data.records[0].zipgroup,
          });
        }

        // this.setState({
        //   taxonomies: taxonomies,
        // });
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  }
  getFaxTemplate() {
    axios
      .post("/faxApi/fax-template", {
        pharmacy_account: this.props.auth.user.email,
      })
      .then((res) => {
        let result = [];
        for (let i = 0; i < res.data.records.length; i++) {
          let each = res.data.records[i];
          if (each.id !== "") {
            result.push(each);
          }
        }
        this.setState({
          fax_records: result,
        });
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  }

  render() {
    if (
      (this.props.auth.user.expiration_tabula === "" ||
        this.props.auth.user.expiration_tabula === undefined ||
        this.props.auth.user.expiration_tabula < moment().format()) &&
      this.props.auth.user.superadmin !== true
    ) {
      window.location.href = "/choose-plan?solution=TabulaRx";
    }
    return (
      <div className="d-flex" id="wrapper">
        {this.state.loading && (
          <div className="loading-wrapper">
            <PulseLoader color="#007bff" loading={true} size={20} />
          </div>
        )}
        <DoctorAddModal getData={this.getData} />
        <FaxTemplatePlan />
        <SendFax
          record={this.state.fax_records}
          email={this.props.auth.user.final_user}
          totalNpis={this.state.totalCount}
          // totalNpis={1}
          filters={{
            ...(this.state.npi !== "" &&
              this.state.npi !== undefined && { npi: this.state.npi }),
            ...(this.state.first_name !== "" && {
              first_name: this.state.first_name,
            }),
            ...(this.state.last_name !== "" &&
              this.state.last_name !== undefined && {
                last_name: this.state.last_name,
              }),
            ...(this.state.address_city !== "" &&
              this.state.address_city !== undefined && {
                address_city: this.state.address_city,
              }),
            ...(this.state.address_state !== "" &&
              this.state.address_state !== undefined && {
                address_state: this.state.address_state,
              }),
            ...(this.state.address_postal !== "" &&
              this.state.address_postal !== undefined &&
              this.state.address_postal !== null && {
                address_postal: this.state.address_postal.split(","),
              }),
            ...(this.state.address_telephone !== "" &&
              this.state.address_telephone !== undefined && {
                address_telephone: this.state.address_telephone,
              }),
            ...(this.state.taxonomy.length > 0 && {
              taxonomy: this.state.taxonomy.map((a) => a.value),
            }),
          }}
        />

        <DoctorUpdateModal
          record={this.state.currentRecord}
          getData={this.getData}
        />
        <div id="page-content-wrapper">
          <div className="container-fluid">
            {this.props.auth.user.superadmin && (
              <button
                className="btn btn-primary float-right mt-3 mr-2"
                data-toggle="modal"
                data-target="#add-doctor-modal"
              >
                <FontAwesomeIcon icon={faPlus} /> Add NPI
              </button>
            )}
            <h1 className="mt-2 text-primary">Provider Database</h1>
            <button className="btn btn-warning">
              <FontAwesomeIcon icon={faBackward} />
              <Link to="/dashboard"> Go to Dashboard</Link>
            </button>
            <br></br>
            <br></br>
            <div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultChecked={this.state.isFilterOpen}
                  onClick={this.toggleChange}
                  id="isFilterOpen"
                />
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  Show Filter
                </label>
              </div>
              <Collapse isOpened={this.state.isFilterOpen}>
                <form noValidate onSubmit={this.onSearch}>
                  <div className="form-row">
                    <div className="form-group col-md-3">
                      <label htmlFor="npi">NPI</label>
                      <input
                        onChange={this.onChange}
                        value={this.state.npi}
                        id="npi"
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="first_name">First Name</label>
                      <input
                        onChange={this.onChange}
                        value={this.state.first_name}
                        id="first_name"
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="last_name">Last Name</label>
                      <input
                        onChange={this.onChange}
                        value={this.state.last_name}
                        id="last_name"
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="address_city">City</label>
                      <input
                        onChange={this.onChange}
                        value={this.state.address_city}
                        id="address_city"
                        type="text"
                        className="form-control"
                      />
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group col-md-3">
                      <label htmlFor="address_state">State</label>
                      <select
                        name="address_state"
                        onClick={this.onChangeSelect}
                        className="form-control"
                        id="address_state"
                      >
                        <option value="" defaultValue>
                          Any
                        </option>
                        {Object.keys(usState).map((each, i) => (
                          <option
                            key={i}
                            value={each}
                            selected={this.state.state === each}
                          >
                            {usState[each]}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="address_telephone">Telephone</label>
                      <input
                        onChange={this.onChange}
                        value={this.state.address_telephone}
                        id="address_telephone"
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="address_postal">
                        Speciality (Taxonomy)
                      </label>
                      <Multiselect
                        selectedValues={this.state.taxonomy}
                        options={this.state.taxonomies}
                        onSelect={this.onSelectMultiple} // Function will trigger on select event
                        onRemove={this.onSelectMultiple} // Function will trigger on remove event
                        displayValue="label"
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="address_postal">
                        Zip Code(Comma separated)
                      </label>
                      <input
                        onChange={this.onChange}
                        value={this.state.address_postal}
                        id="address_postal"
                        type="text"
                        className="form-control"
                      />
                      <button
                        type="button"
                        onClick={this.onZipAdd}
                        className="btn btn-primary sticktoright"
                      >
                        Zipcode Save
                      </button>
                    </div>
                  </div>

                  <button type="submit" className="btn btn-primary">
                    Search
                  </button>
                  <button
                    type="reset"
                    onClick={this.resetSearch}
                    className="btn btn-secordry"
                  >
                    Reset
                  </button>
                </form>
              </Collapse>
            </div>
            <br></br>

            <ReactDatatable
              config={this.config}
              records={this.state.records}
              columns={this.columns}
              onChange={this.filter}
              dynamic={true}
              total_record={this.state.totalCount}
              loading={true}
              exportToCSV={this.exportToCSV}
              exportToPDF={this.exportToPDF}
              advanceSearch={this.advanceSearch}
              sendFax={this.SendFax}
              fax_points={this.state.fax_points}
            />
          </div>
        </div>
        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  records: state.records,
  filter: state.filterData,
});

export default connect(mapStateToProps, { logoutUser, setFilter })(Doctors);
