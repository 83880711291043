import React from "react";
import classnames from "classnames";
import isEmpty from "is-empty";
import axios from "axios";
import { connect } from "react-redux";
import { addUser } from "../../actions/userActions";
import { withRouter } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import $ from "jquery";
import "react-toastify/dist/ReactToastify.css";
import { css } from "@emotion/react";
import CreditCardInput from "react-credit-card-input";
import PulseLoader from "react-spinners/PulseLoader";
import usState from "../../utils/state.json";
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const faxOptions = {
  "500": "$36.99",
  "1000": "$69.99",
  "1500": "$99.99",
  "2000": "$129.99",
  "5000": "$299.99",
  "10000": "$579.99",
};
class AddFaxCredits extends React.Component {
  constructor() {
    super();
    this.state = {
      errors: {},
      cardNumber: "",
      expiry: "",
      cvv: "",
      records: [],
      cardError: true,
      loading: false,
      name: "",
      street: "",
      city: "",
      state: "",
      zip: "",
      amount: 0,
      faxType: undefined,
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSave = (e) => {
    let { errors } = this.state;
    e.preventDefault();
    if (this.state.name === "") {
      errors.name = "This field is required";
    } else {
      delete errors.name;
    }
    if (this.state.faxType === undefined || this.state.faxType === "") {
      errors.faxType = "This field is required";
    } else {
      delete errors.faxType;
    }
    if (this.state.street === "") {
      errors.street = "This field is required";
    } else {
      delete errors.street;
    }
    if (this.state.city === "") {
      errors.city = "This field is required";
    } else {
      delete errors.city;
    }
    if (this.state.state === "") {
      errors.state = "This field is required";
    } else {
      delete errors.state;
    }
    if (this.state.zip === "") {
      errors.zip = "This field is required";
    } else {
      delete errors.zip;
    }

    if (this.state.cardError === false && isEmpty(errors)) {
      this.setState({
        loading: true,
      });
      const newCard = {
        email: this.props.auth.user.final_user,
        cardNumber: this.state.cardNumber,
        expiry: this.state.expiry,
        cvc: this.state.cvc,
        name: this.state.name,
        street: this.state.street,
        city: this.state.city,
        state: this.state.state,
        zip: this.state.zip,
        faxType: this.state.faxType,
      };
      axios
        .post("/faxApi/add-faxcredit", newCard)
        .then((res) => {
          if (!res.data.success) {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_CENTER,
            });
            this.setState({ loading: false });
          } else {
            this.props.sendPoints();
            $("#add-credits").modal("hide");
            this.setState({
              loading: false,
              cardNumber: "",
              expiry: "",
              cvv: "",
              name: "",
              street: "",
              city: "",
              state: "",
              zip: "",
              faxType: 0,
            });
            toast(res.data.message, {
              position: toast.POSITION.TOP_CENTER,
            });
          }
        })
        .catch((err) => {
          this.setState({
            loading: false,
          });
          toast.error(err.response.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    } else {
      this.setState({ errors });
    }
  };

  handleCardNumberChange = (e) => {
    this.setState({
      cardNumber: e.target.value,
      cardError: false,
    });
  };

  handleCardExpiryChange = (e) => {
    this.setState({
      expiry: e.target.value,
      cardError: false,
    });
  };

  handleCardCVCChange = (e) => {
    this.setState({
      cvc: e.target.value,
      cardError: false,
    });
  };

  setError = () => {
    this.setState({
      cardError: true,
    });
  };

  render() {
    const { errors } = this.state;
    const iFrame =
      '<div class="AuthorizeNetSeal"> <script type="text/javascript" language="javascript">var ANS_customer_id="308dab4c-21ef-414b-a2ca-db0d7684fa3d";</script> <script type="text/javascript" language="javascript" src="https://verify.authorize.net:443/anetseal/seal.js" ></script> </div>';
    return (
      <div>
        <div className="modal fade" id="add-credits" data-reset="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Add Fax Credits</h4>

                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <form noValidate onSubmit={this.onUpload} id="add-faxpoints">
                  <div className="row mt-2">
                    <div className="col-md-12">
                      <h5 htmlFor="name">Fax option</h5>
                    </div>
                    <div className="col-md-6">
                      <select
                        name="faxType"
                        onClick={this.onChange}
                        className="form-control"
                        id="faxType"
                      >
                        <option value="" defaultValue>
                          Please Select
                        </option>
                        {Object.keys(faxOptions).map((option, index) => (
                          <option
                            value={option}
                            key={index}
                            selected={option === this.state.faxType}
                          >
                            {option} Credits - {faxOptions[option]}
                          </option>
                        ))}
                      </select>
                      <span className="text-danger">{errors.faxType}</span>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-12">
                      <h5 htmlFor="name">Card Details</h5>
                    </div>
                    <div className="col-md-12">
                      <CreditCardInput
                        onError={() => this.setError()}
                        cardNumberInputProps={{
                          value: this.state.cardNumber,
                          onChange: this.handleCardNumberChange,
                        }}
                        cardExpiryInputProps={{
                          value: this.state.expiry,
                          onChange: this.handleCardExpiryChange,
                        }}
                        cardCVCInputProps={{
                          value: this.state.cvc,
                          onChange: this.handleCardCVCChange,
                        }}
                        fieldClassName="input"
                        customTextLabels={{
                          cardNumberPlaceholder: "0000 0000 0000 0000",
                        }}
                      />
                    </div>
                    <div className="col-md-2">
                      <iframe
                        style={{
                          height: "95px",
                          borderWidth: 0,
                        }}
                        title={"badge"}
                        srcDoc={iFrame}
                      />
                    </div>
                    <div className="col-md-2">
                      <a
                        href="//www.securitymetrics.com/site_certificate?id=1993241&tk=f04ccba354c624538cc9b56b9153a5ec"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src="https://www.securitymetrics.com/portal/app/ngsm/assets/img/BlueContent_Credit_Card_Safe_White_Sqr.png"
                          alt="SecurityMetrics card safe certification logo"
                          height={100}
                        />
                      </a>
                    </div>
                  </div>
                  <div className="row mt-12">
                    <div className="col-md-12">
                      <h5 htmlFor="name">Billing Address</h5>
                    </div>
                    <div className="col-md-6 col-12">
                      <label htmlFor="name">Name</label>
                      <input
                        onChange={this.onChange}
                        value={this.state.name}
                        id="name"
                        type="text"
                        error={errors.name}
                        className={classnames("form-control", {
                          invalid: errors.name,
                        })}
                      />
                      <span className="text-danger">{errors.name}</span>
                    </div>
                    <div className="col-md-6 col-12">
                      <label htmlFor="street">Address</label>
                      <input
                        onChange={this.onChange}
                        value={this.state.street}
                        id="street"
                        type="text"
                        error={errors.street}
                        className={classnames("form-control", {
                          invalid: errors.street,
                        })}
                      />
                      <span className="text-danger">{errors.street}</span>
                    </div>

                    <div className="col-md-6 col-12">
                      <label htmlFor="city">City</label>
                      <input
                        onChange={this.onChange}
                        value={this.state.city}
                        id="city"
                        type="text"
                        error={errors.city}
                        className={classnames("form-control", {
                          invalid: errors.city,
                        })}
                      />
                      <span className="text-danger">{errors.city}</span>
                    </div>

                    <div className="col-md-6 col-12">
                      <label htmlFor="state">State</label>
                      <select
                        name="state"
                        onChange={this.onChange}
                        className="form-control"
                        id="state"
                      >
                        <option value="" selected="">
                          Please Select
                        </option>
                        {Object.keys(usState).map((each, i) => (
                          <option
                            key={i}
                            value={each}
                            selected={this.state.state === each}
                          >
                            {usState[each]}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="col-md-6 col-12">
                      <label htmsuperadminlFor="zip">Zip</label>
                      <input
                        onChange={this.onChange}
                        value={this.state.zip}
                        id="zip"
                        type="text"
                        error={errors.zip}
                        className={classnames("form-control", {
                          invalid: errors.zip,
                        })}
                      />
                      <span className="text-danger">{errors.zip}</span>
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  form="add-faxpoints"
                  type="submit"
                  onClick={this.onSave}
                  className="btn btn-primary"
                >
                  Add Credits
                </button>
              </div>
              <PulseLoader
                color="#e2e2e2"
                loading={this.state.loading}
                css={override}
                size={150}
              />
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { addUser })(withRouter(AddFaxCredits));
